<template>
  <div>
    <ListHeader entity-name="user"></ListHeader>
    <Modal model="user" @confirm-deletion="deleteRecord"/>
    <div class="default-according style-1 faq-accordion default-accordion" id="accordionoc">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-3 xl-40">
            <FilterCard :search-fields="searchFields"
                        :submit-button="searchButton"
                        @searchRecords="search">
            </FilterCard>
          </div>
          <div class="col-xl-9 xl-60">
            <b-card class="m-b-20" no-body v-for="(user, id) in records.items" :key="id">
              <CardHeader :id="id" model-name="User" :record="user"
                          @sendRecord="sendRecord"></CardHeader>
              <b-collapse :id="`${id}`" role="tabpanel">
                <b-card-body class="pre">
                  <div class="row">
                    <p class="col-3 p-r-0 mb-2">
                      <strong>{{ $t('models.user.attributes.email') }}</strong>
                    </p>
                    <p class="col-9 mb-2" v-text="user.email"></p>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
            <div class="pagination" v-if="countNotZero">
              <pagination :options="options" :pages="pages" @paginate="paginate"/>
            </div>
            <div v-else>
              {{ $t('messages.error.noContent') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import ListsMixin from 'mixins/ListsMixin';

export default {
  name: 'UsersList',
  data() {
    return {
      modelName: 'user',
      searchFields: {
        name: {
          type: 'text',
          key: 'name',
          value: '',
          label: this.$t('models.user.attributes.name'),
          classes: 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-0',
          valid: true
        },
        email: {
          type: 'text',
          key: 'email',
          value: '',
          label: this.$t('models.user.attributes.email'),
          classes: 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-0',
          valid: true
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      records: 'users'
    })
  },
  methods: {
    ...mapActions({
      getRecords: 'getUsers',
      destroyRecord: 'destroyUser'
    }),
    getFilters(data) {
      return {
        orAttributes: { name: data.name, email: data.email }
      };
    }
  },
  mixins: [ListsMixin]
};
</script>
